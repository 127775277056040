import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_PUBLISH_METADATA } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },

    {
        title: 'Campaign name',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'advertiser_id',
                identifier: 'tiktok_campaign-advertiser_id',
                label: 'Advertiser',
                metadataType: 'tiktokAdAccount',
                config: {
                    businessCenterId: '6898227998139351042',
                    assetType: 'ADVERTISER'
                }
            }
        ]
    },
    {
        title: 'Advertising objective',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'select',
                model: 'objective_type',
                label: 'Objective',
                identifier: 'tiktok_campaign-objective_type',
                tooltip: 'The ultimate goal of your marketing campaign.',
                options: {
                    REACH: 'Reach',
                    TRAFFIC: 'Traffic',
                    ENGAGEMENT: 'Community interaction',
                    LEAD_GENERATION: 'Lead generation',
                    PRODUCT_SALES: 'Product sales',
                    APP_PROMOTION: 'App promotion',
                    WEB_CONVERSIONS: 'Web conversions',
                    VIDEO_VIEWS: 'Video views'
                }
            }
        ]
    },
    {
        title: 'Campaign type',
        itemType: 'block',
        type: 'multiInput',
        condition: `additionalVars.brick.${MODEL_PUBLISH_METADATA}.objective_type === 'APP_PROMOTION'`,
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                identifier: 'tiktok_campaign-app_promotion_type',
                model: 'app_promotion_type',
                label: 'App promotion type',
                options: {
                    APP_INSTALL: 'App install',
                    APP_RETARGETING: 'App retargeting'
                }
            }
        ]
    },
    {
        title: 'iOS 14 dedicated campaign',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        condition: `additionalVars.brick.${MODEL_PUBLISH_METADATA}.objective_type === 'APP_PROMOTION'`,
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'campaign_type',
                identifier: 'tiktok_campaign-campaign_type',
                label: 'Campaign type',
                options: {
                    REGULAR_CAMPAIGN: 'REGULAR_CAMPAIGN',
                    IOS14_CAMPAIGN: 'IOS14_CAMPAIGN'
                }
            },
            {
                type: 'text',
                model: 'app_id',
                identifier: 'tiktok_campaign-app_id',
                label: 'App',
                condition: "blockData.campaign_type==='IOS14_CAMPAIGN'",
                useValueObject: false
            }
        ]
    },
    {
        title: 'Settings',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'budget_optimize_on',
                identifier: 'tiktok_campaign-budget_optimize_on',
                label: 'Budget optimization',
                //  defaultValue: 'False',
                tooltip: 'Whether to enable Campaign Budget Optimization (CBO).',
                options: {
                    false: 'OFF',
                    true: 'ON'
                }
            },
            {
                type: 'autocomplete',
                model: 'special_industries',
                identifier: 'tiktok_campaign-special_ad_industries',
                label: 'Special ad category',
                tooltip: 'The campaign special ad category',
                options: ['Housing', 'Employment', 'Credit']
            },

            {
                type: 'buttonGroup',
                model: 'operation_status',
                identifier: 'tiktok_campaign-operation_status',
                label: 'Operation status',
                //  defaultValue: 'ENABLE',
                options: {
                    ENABLE: 'ENABLE',
                    DISABLE: 'DISABLE'
                }
            },
            {
                type: 'buttonGroup',
                model: 'budget_mode',
                identifier: 'tiktok_campaign-budget_mode',
                label: 'Budget mode',
                //     defaultValue: 'BUDGET_MODE_TOTAL',
                tooltip: 'Type of the budget',
                options: {
                    BUDGET_MODE_TOTAL: 'TOTAL',
                    BUDGET_MODE_DAY: 'DAY',
                    BUDGET_MODE_DYNAMIC_DAILY_BUDGET: 'DYNAMIC DAILY BUDGET'
                }
            },
            {
                type: 'number',
                tooltip: 'Lifetime campaign budget',
                identifier: 'tiktok_campaign-budget',
                model: 'budget',
                label: 'Budget'
            }
        ]
    }
];

export default settings;
