import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'settings.title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Publish',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'advertiser_id',
                identifier: 'tiktok_ad-advertiser_id',
                label: 'Advertiser',
                metadataType: 'tiktokAdAccount',
                config: {
                    businessCenterId: '6898227998139351042',
                    assetType: 'ADVERTISER'
                }
            },
            {
                type: 'select',
                model: 'identity_type',
                identifier: 'tiktok_ad-identity_type',
                label: 'Identity type',
                tooltip: 'Identity type',
                options: {
                    TT_USER: 'TikTok User',
                    CUSTOMIZED_USER: 'Customized user',
                    AUTH_CODE: 'Authentication code',
                    BC_AUTH_TT: 'Business center authenticated user'
                }
            },
            {
                type: 'metadataInput',
                model: 'identity_id',
                identifier: 'tiktok_ad-identity_id',
                label: 'Identity id',
                metadataType: 'tiktokAccount',
                config: {
                    businessCenterId: '6898227998139351042',
                    assetType: 'TT_ACCOUNT'
                }
            },
            {
                type: 'select',
                model: 'ad_format',
                identifier: 'tiktok_ad-ad_format',
                label: 'Ad format',
                tooltip: 'Ad format',
                options: {
                    SINGLE_IMAGE: 'Single image',
                    SINGLE_VIDEO: 'Single video',
                    LIVE_CONTENT: 'Live content',
                    CAROUSEL_ADS: 'Carousel ads',
                    CATALOG_CAROUSEL: 'Catalog carousel'
                }
            }
            // {
            //     type: 'text',
            //     model: 'settings.publish.channels.facebook.ad.audience_id',
            //     itemType: 'input',
            //     label: 'Audience id',
            //     useValueObject: false
            // },

            // {
            //     type: 'number',
            //     tooltip:
            //         'Bid amount for this ad which will be used in auction instead of the ad set bid_amount, if specified. Any updates to the ad set bid_amount will overwrite this value with the new ad set value.',
            //     model: 'settings.publish.channels.facebook.ad.topline_id',
            //     label: 'Bid amount'
            // },
            // {
            //     type: 'text',
            //     model: 'settings.publish.channels.facebook.ad.conversion_domain',
            //     itemType: 'input',
            //     label: 'Conversion domain',
            //     tooltip:
            //         'The domain where conversions happen. Required to create or update an ad in a campaign that shares data with a pixel. This field will be auto-populated for existing ads by inferring from destination URLs . Note that this field should contain only the first and second level domains, and not the full URL. For example facebook.com.',
            //     useValueObject: false
            // },
            // {
            //     type: 'text',
            //     model: 'settings.publish.channels.facebook.ad.date_format',
            //     itemType: 'input',
            //     tooltip: 'The format of the date',
            //     label: 'Date format',
            //     useValueObject: false
            // },
            // {
            //     type: 'number',
            //     tooltip: 'The sequence of the ad within the same campaign',
            //     model: 'settings.publish.channels.facebook.ad.disolay_sequence',
            //     label: 'Display sequence'
            // },
            // {
            //     type: 'number',
            //     tooltip: 'The ID of the draft ad.',
            //     model: 'settings.publish.channels.facebook.ad.disolay_sequence',
            //     label: 'Draft ad group id'
            // },
            // {
            //     type: 'buttonGroup',
            //     model: 'settings.publish.channels.facebook.engagement_audience',
            //     label: 'Engagement audience',
            //     tooltip: 'Flag to create a new audience based on users who engage with this ad',
            //     defaultValue: 'false',
            //     options: {
            //         false: 'OFF',
            //         true: 'ON'
            //     }
            // },
            // {
            //     type: 'buttonGroup',
            //     model: 'settings.publish.channels.facebook.include_demolink_hashes',
            //     label: 'Include demolink hashes',
            //     tooltip: 'Include the demolink hashes.',
            //     defaultValue: 'false',
            //     options: {
            //         false: 'OFF',
            //         true: 'ON'
            //     }
            // },
            // {
            //     type: 'number',
            //     tooltip: 'The ID of the draft ad.',
            //     model: 'settings.publish.channels.facebook.ad.priority',
            //     label: 'Priority'
            // },
            // {
            //     type: 'text',
            //     model: 'settings.publish.channels.facebook.ad.tracking_specs',
            //     itemType: 'input',
            //     label: 'Tracking specs',
            //     useValueObject: false
            // }
        ]
    }
];

export default settings;
