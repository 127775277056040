const validators = {
    requiredLevels: {
        parent: 'tiktok_campaign'
    },
    validators: [
        {
            condition: '!brick.data.adgroup_name || !brick.data.adgroup_name.length',
            message: 'Ad group should have name'
        },

        {
            condition: '!brick.data.advertiser_id || !brick.data.advertiser_id.length',
            message: 'Advertiser id should be set'
        },
        {
            condition: 'parent.data.budget_optimize_on!=="true" && !brick.data.budget',
            message: 'When campaign budget optimization is disabled ad group should have budget'
        },
        {
            condition: 'parent.data.budget_optimize_on!=="true" && !brick.data.budget_mode',
            message: 'When campaign budget optimization is disabled ad group should have budget mode'
        },
        {
            condition: '!brick.data.schedule_type',
            message: 'Schedule type is required'
        },
        {
            condition: '!brick.data.schedule_start_time',
            message: 'Schedule start time should be specified for ad group'
        },

        {
            condition: '!brick.data.billing_event',
            message: 'Billing event should be specified for ad group'
        },
        {
            condition: '!brick.data.placement_type',
            message: 'Placement type is required'
        },
        {
            condition: 'parent.data.objective_type!=="REACH" && brick.data.placement_type==="PLACEMENT_TYPE_AUTOMATIC"',
            message: 'Reach objective campaign can have only normal placements type'
        },

        {
            condition: 'parent.data.objective_type==="REACH" && !["CPM"].includes(brick.data.billing_event)',
            message: 'Campaign with REACH objective can have only CPM(Pay for one thousand impressions) as billing event'
        },
        {
            condition: 'parent.data.objective_type==="PRODUCT_SALES" && !["OCPM","CPC"].includes(brick.data.billing_event)',
            message: 'Campaign with Product Sales objective can have only OCPM and CPC as billing event'
        },
        {
            condition: 'parent.data.objective_type==="WEBSITE_CONVERSIONS" && !["OCPM","CPC"].includes(brick.data.billing_event)',
            message: 'Campaign with Website Conversions objective can have only OCPM and CPC as billing event'
        },
        {
            condition: 'parent.data.objective_type==="APP_PROMOTION" && !["OCPM","CPC"].includes(brick.data.billing_event)',
            message: 'Campaign with App Promotion objective can have only OCPM and CPC as billing event'
        },
        {
            condition: 'parent.data.objective_type==="ENGAGEMENT" && !["OCPM","CPC"].includes(brick.data.billing_event)',
            message: 'Campaign with ENGAGEMENT objective can have only OCPM and CPC as billing event'
        },
        {
            condition: 'parent.data.objective_type==="LEAD_GENERATION" && !["OCPM"].includes(brick.data.billing_event)',
            message: 'Campaign with LEAD GENERATION objective can have only OCPM as billing event'
        },
        {
            condition: 'parent.data.objective_type==="TRAFFIC" && !["CPC"].includes(brick.data.billing_event)',
            message: 'Campaign with TRAFFIC objective can have only CPC as billing event'
        },
        {
            condition: 'parent.data.objective_type==="VIDEO_VIEWS" && !["CPV"].includes(brick.data.billing_event)',
            message: 'Campaign with VIDEO VIEWS objective can have only CPV as billing event'
        },
        {
            condition: 'parent.data.objective_type==="REACH" && brick.data.bid_type!=="BID_TYPE_NO_BID"',
            message: 'Campaign with REACH objective can have only BID TYPE NO BID as bid strategy'
        },
        {
            condition: 'parent.data.objective_type==="VIDEO_VIEWS" && brick.data.bid_type!=="BID_TYPE_NO_BID"',
            message: 'Campaign with VIDEO VIEWS objective can have only BID TYPE NO BID as bid strategy'
        },
        {
            condition: 'parent.data.objective_type==="TRAFFIC" && brick.data.bid_type!=="BID_TYPE_NO_BID"',
            message: 'Campaign with TRAFFIC objective can have only BID TYPE NO BID as bid strategy'
        },
        {
            condition: 'parent.data.objective_type==="PRODUCT_SALES" && brick.data.bid_type!=="BID_TYPE_NO_BID"',
            message: 'Campaign with Product sales objective can have only BID TYPE NO BID as bid strategy'
        },
        {
            condition:
                'parent.data.objective_type==="WEBSITE_CONVERSIONS" && (brick.data.bid_type!=="BID_TYPE_NO_BID" || (brick.data.bid_type === "BID_TYPE_CUSTOM" && brick.data.optimization_goal!=="CONVERT" ))',
            message: 'Campaign with website conversions can have only bid type Bid Type No Bid or Bid type custom but with optimization goal Convert'
        },
        {
            condition: 'brick.data.bid_type==="BID_TYPE_NO_BID" && !["CPC","CPM","CPV","OCPM"].includes(brick.data.billing_event)',
            message: 'Bidding strategy Bid Type No Bid can have only CPC,CPM,CPV,OCPM as billing events'
        },
        {
            condition: 'brick.data.bid_type==="BID_TYPE_NO_BID" && !brick.data.bid_price',
            message: 'Bidding strategy Bid Type No Bid should have a bid price'
        },
        {
            condition: 'brick.data.bid_type==="BID_TYPE_CUSTOM" && !["OCPM"].includes(brick.data.billing_event)',
            message: 'Bidding strategy Bid Type CUSTOM can have only OCPM as billing events'
        },

        {
            condition:
                "parent.data.special_industries && parent.data.special_industries.length && ((brick.data.gender && brick.data.gender !== 'GENDER_UNLIMITED') || (brick.data.age_groups && brirck.data.age_groups.includes('AGE_13_17')))",
            message: "For campaign with special industries the gender needs to be 'Gender unlimited' and the target age groups should be older that 18 years"
        },

        {
            condition: "parent.data.budget_optimize_on==='true' && parent.data.objective_type==='REACH' && !['REACH'].includes(brick.data.optimization_goal)",
            message: "When campaign budget optimization is activated a campaign with objective type 'Reach' can only have sets with optimization goals 'Reach'"
        },
        {
            condition:
                "parent.data.budget_optimize_on==='true' && parent.data.objective_type==='VIDEO_VIEWS' && !['ENGAGED_VIEW','ENGAGED_VIEW_FIFTEEN'].includes(brick.data.optimization_goal)",
            message:
                "When campaign budget optimization is activated a campaign with objective type 'Video views' can only have sets with optimization goals 'Engaged view' and 'Engaged view fifteen'"
        },
        {
            condition:
                "parent.data.budget_optimize_on==='true' && parent.data.objective_type==='TRAFFIC' && !['CLICK','TRAFFIC_LANDING_PAGE'].includes(brick.data.optimization_goal)",
            message:
                "When campaign budget optimization is activated a campaign with objective type 'Traffic' can only have sets with optimization goals 'Click' and 'Traffic landing page'"
        },
        {
            condition:
                "parent.data.budget_optimize_on==='true' && parent.data.objective_type==='WEB_CONVERSIONS' && !['CONVERT','VALUE'].includes(brick.data.optimization_goal)",
            message:
                "When campaign budget optimization is activated a campaign with objective type 'Web conversions' can only have sets with optimization goals 'Convert' and 'Value'"
        },
        {
            condition:
                "parent.data.budget_optimize_on==='true' && parent.data.objective_type==='APP_PROMOTION' && !['INSTALL','IN_APP_EVENT','VALUE'].includes(brick.data.optimization_goal)",
            message:
                "When campaign budget optimization is activated a campaign with objective type 'App promotion' can only have sets with optimization goals 'Install' and 'In app event', 'Value'"
        },
        {
            condition:
                "parent.data.budget_optimize_on==='true' && parent.data.objective_type==='LEAD_GENERATION' && !['LEAD_GENERATION'].includes(brick.data.optimization_goal)",
            message:
                "When campaign budget optimization is activated a campaign with objective type 'Lead generation' can only have sets with optimization goal 'Lead generation'"
        },
        {
            condition:
                "parent.data.budget_optimize_on==='true' && parent.data.objective_type==='ENGAGEMENT' && !['FOLLOWERS','PROFILE_VIEWS'].includes(brick.data.optimization_goal)",
            message:
                "When campaign budget optimization is activated a campaign with objective type 'Engagement' can only have sets with optimization goals 'Followers' and 'Profile views'"
        },
        {
            condition: 'brick.data.budget && (brick.data.budget<20 || brick.data.budget>=10000000)',
            message: 'Set should have budget and it have to be more or equal to 20 and below 10000000'
        },
        {
            condition: '!brick.data.budget_mode && !parent.data.budget_mode',
            message: 'Set or campaign should have budget'
        },
        {
            condition:
                "brick.data.budget_mode && parent.data.budget_mode && parent.data.budget_mode === 'BUDGET_MODE_DAY' && brick.data.budget_mode ==='BUDGET_MODE_TOTAL'",
            message: "Budget can't be with budget mode total while campain is with budget mode daily"
        },
        {
            condition: "brick.data.placement_type === 'PLACEMENT_TYPE_NORMAL' && (!brick.data.placements || !brick.data.placements.value.length)",
            message: 'The apps where you want to deliver your ads. Required when placement_type is PLACEMENT_TYPE_NORMAL'
        },
        {
            condition:
                "parent.data.objective_type==='PRODUCT_SALES' && brick.data.placements && (brick.data.placements.value.length!==1 || brick.data.placements.value[0]!=='PLACEMENT_TIKTOK')",
            message: 'For Product Sales campaigns (objective_type = PRODUCT_SALES), only TikTok placement (PLACEMENT_TIKTOK) is supported.'
        },
        {
            condition:
                "parent.data.objective_type === 'APP_PROMOTION' && !brick.data.app_id && (parent.data.app_promotion_type==='APP_RETARGETING' || (parent.data.app_promotion_type==='APP_INSTALL' && parent.data.campaign_type!=='IOS14_CAMPAIGN'))",
            message:
                '!pp id in set is required when objective_type is APP_PROMOTION and app_promotion_type is APP_RETARGETING (App retargeting) or when objective_type is APP_PROMOTION , app_promotion_type is APP_INSTALL(App install), and it is not an iOS14 Dedicated parent.data.'
        },
        {
            condition:
                "!brick.data.promotion_type && parent.data.objective_type !== 'VIDEO_VIEWS' && parent.data.objective_type !== 'VIDEO_VIEWS' && parent.data.objective_type !== 'REACH'",
            message:
                "Promotion type and you can decide where you'd like to promote your products using this field. You need to specify the field when advertising objective (objective_type) for your campaign is NOT set as REACH, VIDEO_VIEWS, or ENGAGEMENT."
        },
        {
            condition: "!brick.data.product_source && parent.data.objective_type === 'PRODUCT_SALES' && brick.data.shopping_ad_type === 'VIDEO'",
            message: 'Product source is required for Video Shopping Ads.'
        },
        {
            condition:
                "!parent.data.shopping_ads_retargeting_actions_days && (brick.data.shopping_ads_retargeting_type && (brick.data.shopping_ads_retargeting_type==='LAB1' || brick.data.shopping_ads_retargeting_type==='LAB2'))",
            message: 'Shopping ad retargeting type is required when shopping_ads_retargeting_type is LAB1 or LAB2.'
        },
        {
            condition:
                "parent.data.campaign_objective === 'PRODUCT_SALES' && brick.data.shopping_ads_retargeting_type && brick.data.shopping_ads_retargeting_type === 'LAB3' && !brick.data.excluded_custom_actions && !brick.data.included_custom_actions",
            message:
                "The custom action that you want to use as 'Include' conditions for filtering out the shopping ads audiences to be retargeted. When shopping_ads_retargeting_type is LAB3, you need to pass in either included_custom_actions or excluded_custom_actions"
        },
        {
            condition: "parent.data.campaign_objective === 'PRODUCT_SALES' && !brick.data.store_id",
            message: 'ID of the TikTok Storefront or TikTok Shop. Valid and required only when objective_type is PRODUCT_SALES.'
        },
        {
            condition: "parent.data.campaign_objective ==='PRODUCT_SALES' && !brick.data.store_authorized_bc_id",
            message: 'Store authorized Businnes center ID is required when campaign objective_type is PRODUCT_SALES.'
        },
        {
            condition: "parent.data.campaign_objective ==='PRODUCT_SALES' && !brick.data.identity_id",
            message: 'Identity id is required and only valid when objective_type is  PRODUCT_SALES. '
        },
        {
            condition: "parent.data.campaign_objective ==='PRODUCT_SALES' && !brick.data.identity_type",
            message: 'Identity type is required and only valid when objective_type is SHOP_PURCHASES or PRODUCT_SALES.'
        },
        {
            condition: "brick.data.identity_type && brick.data.identity_type === 'BC_AUTH_TT' && !brick.data.identity_authorized_bc_id",
            message: 'Identity authorized business centre is required when identity_type is BC_AUTH_TT.'
        },
        {
            condition:
                "((parent.data.campaign_objective==='PRODUCT_SALES' || parent.data.campaign_objective ==='WEB_CONVERSIONS') && !brick.data.pixel_id) || (parent.data.campaign_objective ==='LEAD_GENERATION' && brick.data.promotion_target_type==='EXTERNAL_WEBSITE' && !brick.data.pixel_id)",
            message:
                "Pixel id is required if campaign objective is 'Product sales' or 'WEB_CONVERSIONS' and if you set promotion_target_type to EXTERNAL_WEBSITE under objective LEAD_GENERATION"
        },
        {
            condition: 'brick.data.audienece_type && !brick.data.audience_rule',
            message: 'Audience rule is required when audience_type is specified.'
        },
        {
            condition: "parent.data.objective_type ==='WEB_CONVERSIONS' && !brick.data.audience_type",
            message: 'App retargeting audience type, required when objective_type is CONVERSIONS and external_type is app-related.'
        },
        {
            condition:
                "(!brick.data.operating_systems || !brick.data.operating_systems.length) && (parent.data.objective_type ==='APP_INSTALL' || (parent.data.objective_type==='TRAFFIC' && (brick.data.optimization_event==='APP_IOS' || brick.data.optimization_event ==='APP_ANDROID')))",
            message:
                "Operating systems are required when objective_type is 'APP_INSTALL' of objective is 'TRAFFIC' and optimization_event is 'APP_IOS' or 'APP_ANDROID'"
        },
        {
            condition: "parent.data.campaign_type === 'IOS14_CAMPAIGN' && brick.data.ios14_targeting !== 'IOS14_PLUS'",
            message: 'When campaign_type of the campaign is set as IOS14_CAMPAIGN, ios14_targeting is required and must be specified as IOS14_PLUS'
        },
        {
            condition:
                "brick.data.ios14_targeting==='IOS14_PLUS' && ((brick.data.operating_systems && brick.data.operating_systems.length === 1 && brick.data.operating_systems[0] !=='IOS') || !brick.data.min_ios_version || brick.data.min_android_version || (brick.data.optimization_goal!=='IN_APP_EVENT' || brick.data.optimization_goal !=='INSTALL' || brick.data.optimization_goal!=='CLICK') || brick.data.shopping_ads_retargeting_type || brick.data.shopping_ads_retargeting_actions_days || brick.data.conversion_window)",
            message: 'If IOS14_PLUS is specified for this field, the system will verify if related fields meet the requirements for an iOS 14 parent.data.'
        },
        {
            condition:
                "(brick.data.budget_mode==='BUDGET_MODE_DAY' || parent.data.budget_mode ==='BUDGET_MODE_DAY') && (brick.data.schedule_type !== 'SCHEDULE_START_END' && brick.data.schedule_type !== 'SCHEDULE_FROM_NOW')",
            message: 'If budget_mode is Budget mode daily, schedule_type must be set to Schedule start end  or Schedule from now.'
        },
        {
            condition:
                "(brick.data.budget_mode==='BUDGET_MODE_TOTAL' || parent.data.budget_mode ==='BUDGET_MODE_TOTAL') && (brick.data.schedule_type !== 'SCHEDULE_START_END')",
            message: 'If budget_mode is Budget mode total the Schedule type must be Schedule start end'
        },
        {
            condition: "brick.data.schedule_type === 'SCHEDULE_START_END' && !brick.data.schedule_end_time",
            message: 'Ad group with schedule type schedule start end  should have schedule end time'
        },
        {
            condition: 'parent.data.budget_optimize_on==="true" && !brick.data.optimization_goal',
            message: 'Set must have optimization goal when campaign budget optimization is on'
        },
        {
            condition: "brick.data.optimization_goal && parent.data.objective_type==='REACH' && !['REACH'].includes(brick.data.optimization_goal)",
            message: "Campaign with objective 'Reach' can have only sets with 'Reach' optimization goal"
        },
        {
            condition:
                "brick.data.optimization_goal && parent.data.objective_type==='VIDEO_VIEWS' && !['ENGAGED_VIEW','ENGAGED_VIEW_FIFTEEN'].includes(brick.data.optimization_goal)",
            message: "Campaign with objective 'Video views' can have only sets with 'Engaged view' and 'Engaged view fifteen' optimization goals"
        },
        {
            condition:
                "brick.data.optimization_goal && parent.data.objective_type==='TRAFFIC' && !['CLICK','TRAFFIC_LANDING_PAGE'].includes(brick.data.optimization_goal)",
            message: "Campaign with objective 'Traffic' can have only sets with 'Click' and 'Traffic landing page' optimization goals"
        },
        {
            condition:
                "brick.data.optimization_goal && parent.data.objective_type==='WEB_CONVERSIONS' && !['CONVERT','VALUE'].includes(brick.data.optimization_goal)",
            message: "Campaign with objective 'Web conversions' can have only sets with 'Convert' and 'Value' optimization goals"
        },
        {
            condition:
                "brick.data.optimization_goal && parent.data.objective_type==='APP_PROMOTION' && !['INSTALL','IN_APP_EVENT','VALUE'].includes(brick.data.optimization_goal)",
            message: "Campaign with objective 'App promotion' can have only sets with 'Install','In app event' and 'Value' optimization goals"
        },
        {
            condition:
                "brick.data.optimization_goal && parent.data.objective_type==='LEAD_GENERATION' && !['LEAD_GENERATION'].includes(brick.data.optimization_goal)",
            message: "Campaign with objective 'Lead generation' can have only sets with 'Lead generation' optimization goals"
        },
        {
            condition:
                "brick.data.optimization_goal && parent.data.objective_type==='ENGAGEMENT' && !['FOLLOWERS','PROFILE_VIEWS'].includes(brick.data.optimization_goal)",
            message: "Campaign with objective 'Engagement' can have only sets with 'Followers' and 'Profile views' optimization goals"
        },
        {
            condition: 'parent.data.budget_optimize_on!=="true" && !brick.data.pacing',
            message: 'If the campaign budget optimization if off the set pacing is required'
        },
        {
            condition: 'parent.data.budget_optimize_on==="true" && !brick.data.bid_type',
            message: 'Bid type is required when campaign budget optimization is on'
        },
        {
            condition: "brick.data.bid_price && !brick.data.bid_type && brick.data.bid_type!=='BID_TYPE_CUSTOM'",
            message: 'To specify bid_price, you need to set bid_type as BID_TYPE_CUSTOM'
        },
        {
            condition: "brick.data.conversion_bid_price && brick.data.billing_event === 'OCPM' && brick.data.bid_type === 'BID_TYPE_CUSTOM'",
            message: 'Conversion bid price is required when billing_event = OCPM and bid_type = BID_TYPE_CUSTOM'
        },
        {
            condition: "!brick.data.deep_bid_type && parent.data.budget_optimize_on==='true' && brick.data.optimization_goal === 'VALUE'",
            message:
                "Set deep bid type is required when Campaign Budget Optimization (CBO) is enabled (budget_optimize_on = TRUE) and optimization_goal is 'VALUE'."
        },
        {
            condition:
                "parent.data.objective_type === 'VIDEO_VIEWS' && brick.data.bid_type === 'BID_TYPE_CUSTOM' && (!brick.data.bid_display_mode || brick.data.bid_display_mode !== 'CPV')",
            message: 'Bid display mode is required and must be set to CPV(Cost per One View) when objective_type is VIDEO_VIEW and bid_type is BID_TYPE_CUSTOM.'
        },
        {
            condition: "parent.data.objective_type === 'PRODUCT_SALES' && !brick.data.catalog_id",
            message: 'Catalog id is required when the corresponding campaign objective_type is PRODUCT_SALES'
        },
        {
            condition: 'brick.data.store_authorized_bc_id && !brick.data.catalog_authorized_bc_id',
            message: 'For catalogs in Business Center, you must specify the ID of the Business Center that a catalog belongs to.'
        },
        {
            condition: "brick.data.deep_bid_type && brick.data.deep_bid_type === 'VO_MIN_ROAS' && !brick.data.roas_bid",
            message: 'ROAS goal for Value Optimization. Required when deep_bid_type is VO_MIN_ROAS.'
        },
        {
            condition: 'brick.data.roas_bid && (brick.data.roas_bid < 0.01 || brick.data.roas_bid > 1000)',
            message: 'ROAS bid value range: 0.01-1000.'
        },
        {
            condition: "brick.data.brand_safety_type && brick.data.brand_safety_type === 'THIRD_PARTY' && !brick.data.brand_safety_partner",
            message:
                "If brand safety type is 'THIRD_PARTY' use a third-party brand safety solution. You also need to pass in a value to the brand_safety_partner field."
        },
        {
            condition:
                'brick.data.targeting_expansion && brick.data.targeting_expansion.expansion_enabled && (!brick.data.targeting_expansion.expansion_types || !brick.data.targeting_expansion.expansion_types.length)',
            message: ' Required when expansion_enabled is true. Target audience types that are eligible for expanding must already have a value or selection.'
        },
        {
            condition:
                '(!brick.data.location_ids || (brick.data.location_ids && !brick.data.location_ids.length)) && (!brick.data.zipcode_ids || (brick.data.zipcode_ids && !brick.data.zipcode_ids.length))',
            message: 'Location ids or zipcodes ids is required'
        }
    ]
};

export default validators;
