import briefing from 'components/bricks/components/shared/components/briefing-content/components';
import output from 'components/bricks/components/shared/components/output-tab';
import adSetup from 'components/bricks/components/shared/components/ad-setup';
import adSetupSingleFrame from './ad-setup-single-frame';
import adSetupMultiFrame from './ad-setup-multi-frame';
import settings from './settings';
import setup from './setup';
import metadata from './metadata';
import icon from './icon.svg';
import defaultData from './default-data';
import placements from '../data/placements';
import validators from './validators';

export default { settings, setup, metadata, adSetup, adSetupSingleFrame, adSetupMultiFrame, icon, defaultData, briefing, output, placements, validators };
