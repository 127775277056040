const validators = {
    validators: [
        {
            condition: '!brick.data.campaign_name',
            message: 'Campaign should have campaign name'
        },
        {
            condition: '!brick.data.objective_type',
            message: 'Campaign should have objective'
        },
        {
            condition: 'brick.data.campaign_name && brick.data.campaign_name.length>=512',
            message: 'Campaign name should be less than 512 characters'
        },
        {
            condition:
                '!brick.data.budget && (brick.data.budget_mode==="BUDGET_MODE_DAY" || brick.data.budget_mode==="BUDGET_MODE_TOTAL" || brick.data.budget_mode==="BUDGET_MODE_DYNAMIC_DAILY_BUDGET")',
            message: 'Budget is required when budget mode is not BUDGET_INFINITE'
        },
        {
            condition: '!brick.data.budget_mode && brick.data.objective_type && brick.data.objective_type!=="RF_REACH"',
            message: 'Budget mode is required when objective is not RF_REACH'
        },
        {
            condition: 'brick.data.budget && (brick.data.budget<50 || brick.data.budget> 10000000)',
            message: 'Campaign should have budget and it have to be more or equal to 50 and below 10000000'
        },
        {
            condition:
                "brick.data.campaign_type==='IOS14_CAMPAIGN' && brick.data.objective_type!=='PRODUCT_SALES' && (brick.data.objective_type!=='APP_PROMOTION' || (brick.data.objective_type === 'APP_PROMOTION' && (!brick.data.app_promotion_type || brick.data.app_promotion_type !== 'APP_INSTALL')))",
            message: 'The value IOS14_CAMPAIGN supports the advertising objectives of PRODUCT_SALES and APP_PROMOTION( its app_promotion_type = APP_INSTALL).'
        },
        {
            condition: "brick.data.campaign_type==='IOS14_CAMPAIGN' && !brick.data.app_id",
            message:
                'Required when the campaign is an iOS 14 Dedicated Campaign (camapign_type=IOS14_CAMPAIGN) with the advertising objective (objective_type) as PRODUCT_SALES, or APP_PROMOTION( its app_promotion_type = APP_INSTALL).'
        },
        {
            condition: "brick.data.objective_type==='RF_REACH' && brick.data.budget_mode && brick.data.budget_mode!=='BUDGET_MODE_INFINITE'",
            message: "When objective_type is 'RF_REACH', budget_mode only supports 'BUDGET_MODE_INFINITE'."
        },
        {
            condition:
                "brick.data.objective_type!=='APP_PROMOTION' && brick.data.objective_type!=='TRAFFIC' && brick.data.objective_type!=='LEAD_GENERATION' && brick.data.objective_type!=='WEB_CONVERSIONS' && brick.data.objective_type!=='PRODUCT_SALES' && brick.data.budget_mode && brick.data.budget_mode === 'BUDGET_MODE_DYNAMIC_DAILY_BUDGET'",
            message:
                "'BUDGET_MODE_DYNAMIC_DAILY_BUDGET' supports the following objective_type: 'TRAFFIC', 'APP_PROMOTION', 'WEB_CONVERSIONS', 'LEAD_GENERATION', 'PRODUCT_SALES'"
        },
        {
            condition:
                "brick.data.objective_type==='APP_PROMOTION' && (!brick.data.app_promotion_type || (brick.data.campaign_type==='IOS14_CAMPAIGN' && brick.data.app_promotion_type!=='APP_INSTALL'))",
            message:
                'App promotion type. Required when objective_type is APP_PROMOTION. Enum values: APP_INSTALL, APP_RETARGETING. Note: APP_INSTALL can be used in an iOS14 Dedicated Campaign, while APP_RETARGETING cannot be used.'
        },
        {
            condition:
                "(brick.data.objective_type==='RF_REACH' && !brick.data.rf_campaign_type) || (brick.data.objective_type!=='RF_REACH' && brick.data.rf_campaign_type)",
            message: "RF campaign type should be passed only if campaign objective is 'RF_REACH'"
        }
    ]
};

export default validators;
