import { MODEL_PUBLISH_METADATA } from 'components/bricks/constants';
import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Ad group name',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'advertiser_id',
                identifier: 'tiktok_adgroup-advertiser_id',
                label: 'Advertiser',
                metadataType: 'tiktokAdAccount',
                config: {
                    businessCenterId: '6898227998139351042',
                    assetType: 'ADVERTISER'
                }
            }
        ]
    },
    {
        title: 'Optimization location',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        condition: `['TRAFFIC','LEAD_GENERATION','WEB_CONVERSIONS'].includes(additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type)`,
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'promotion_target_type',
                label: 'Promotion Target type',
                identifier: 'tiktok_adgroup-promotion_target_type',
                tooltip: 'Valid when objective_type at the campaign level is LEAD_GENERATION.',
                options: {
                    INSTANT_PAGE: 'Instant page',
                    EXTERNAL_WEBSITE: 'External website',
                    UNSET: 'Unset'
                }
            },
            // {
            //     type: 'select',
            //     model: 'promotion_type',
            //     label: 'Promotion type',
            //     identifier: 'tiktok_adgroup-promotion_type',
            //     tooltip: 'Promotion type',
            //     options: {
            //         APP_ANDROID: 'Android application',
            //         APP_IOS: 'iOS application',
            //         WEBSITE: 'Landing page',
            //         LEAD_GENERATION: 'Lead generation ads',
            //         WEBSITE_OR_DISPLAY: 'Landing page or pure display page',
            //         TIKTOK_SHOP: 'TikTok shop / store',
            //         VIDEO_SHOPPING: 'Video shopping',
            //         LIVE_SHOPPING: 'Live shopping'
            //     }
            // },

            {
                type: 'text',
                model: 'pixel_id',
                identifier: 'tiktok_adgroup-pixel_id',
                condition: "blockData.promotion_target_type === 'EXTERNAL_WEBSITE'",
                label: 'Pixel ID',
                tooltip:
                    'Only applicable for landing pages. It needs to be passed in with the optimization_event field, and the supported advertising objectives (objective_type) are CONVERSIONS, PRODUCT_SALES, SHOP_PURCHASES. If you want to track events for other objectives other than LEAD_GENERATION, use tracking_pixel_id at the ad level.',
                useValueObject: false
            },
            {
                type: 'text',
                model: 'optimization_event',
                identifier: 'tiktok_adgroup-optimization_event',
                condition: "blockData.promotion_target_type === 'EXTERNAL_WEBSITE'",
                label: 'Optimization event',
                tooltip: 'Conversion event for the ad group. Required when the promoted object is an App with tracking urls, or when pixel_id is specified.',
                useValueObject: false
            }
            // {
            //     type: 'select',
            //     model: 'secondary_optimization_event',
            //     identifier: 'tiktok_adgroup-secondary_optimization_event',
            //     label: 'Secondary optimization event',
            //     tooltip: 'The secondary goal when optimization goal (optimization_goal) is Install (INSTALL) or Value (VALUE).',
            //     options: {
            //         ACTIVE_PAY: 'Purchase',
            //         ACTIVE_REGISTER: 'Registration',
            //         IN_APP_CART: 'Add to cart',
            //         NEXT_DAY_OPEN: 'Day 1 Retention',
            //         PURCHASE_ROI: 'ROI of the purchase',
            //         SUBSCRIBE: 'Subscribe',
            //         UPDATE_LEVEL: 'Achieve level',
            //         DAY7_RETENTION: 'Day 7 retention'
            //     }
            // }
        ]
    },
    {
        title: 'Creative type',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'creative_material_mode',
                label: 'Creative material mode',
                identifier: 'tiktok_adgroup-creative_material_mode',
                tooltip: 'The strategy that your creatives will be delivered.',
                options: {
                    CUSTOM: 'Custom',
                    DYNAMIC: 'Dynamic'
                }
            }
        ]
    },
    {
        title: 'Placements',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'placement_type',
                identifier: 'tiktok_adgroup-placement_type',
                label: 'Placement type',
                tooltip: 'The placement strategy that decides where your ads will be shown.',
                options: {
                    PLACEMENT_TYPE_AUTOMATIC: 'PLACEMENT TYPE AUTOMATIC',
                    PLACEMENT_TYPE_NORMAL: 'PLACEMENT TYPE NORMAL'
                }
            },
            {
                type: 'checkboxList',
                model: 'placements',
                identifier: 'tiktok_adgroup-placements',
                tooltip: 'The placement strategy that decides where your ads will be shown.',
                label: 'Placements',
                condition: `['TRAFFIC','WEB_CONVERSIONS','APP_PROMOTION'].includes(additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type) && blockData.placement_type==='PLACEMENT_TYPE_NORMAL'`,
                selectType: 'multiple',
                options: { PLACEMENT_TIKTOK: 'TIKTOK', PLACEMENT_PANGLE: 'PANGLE', PLACEMENT_GLOBAL_APP_BUNDLE: 'GLOBAL APP BUNDLE' }
            },

            {
                type: 'checkboxList',
                model: 'placements',
                identifier: 'tiktok_adgroup-placements',
                tooltip: 'The placement strategy that decides where your ads will be shown.',
                label: 'Placements',
                condition: `!['TRAFFIC','WEB_CONVERSIONS','APP_PROMOTION'].includes(additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type) && blockData.placement_type==='PLACEMENT_TYPE_NORMAL'`,
                selectType: 'multiple',
                options: { PLACEMENT_TIKTOK: 'TIKTOK' }
            },

            {
                type: 'switch',
                model: 'comment_disabled',
                identifier: 'tiktok_adgroup-comment_disabled',
                label: 'Comment disabled',
                tooltip: 'Whether to allow comments on your ads on TikTok.'
            },

            {
                type: 'switch',
                model: 'video_download_disabled',
                identifier: 'tiktok_adgroup-video_download_disabled',
                label: 'Is video download disabled?',
                tooltip:
                    "Users based in Japan won't be able to download your video ads.Users can only download Spark Ad posts if this setting has been turned on in both Ads Manager and TikTok."
            },

            {
                type: 'switch',
                model: 'share_disabled',
                identifier: 'tiktok_adgroup-share_disabled',
                label: 'Video sharing',
                tooltip:
                    "Turn on the toggle so users can share your video on TikTok. If the toggle is off, users won't be able to share your video via Instagram, direct message, SMS, Facebook, Twitter, email, or by copying the link. Other sharing options will still be available."
            }

            //TODO:(TO BE DISCUSSED) Retreive it from the backend and make it array
            // {
            //     type: 'text',
            //     model: 'blocked_pangle_app_ids',
            //     label: 'Pangle block list',
            //     maxLength: 256,
            //     helperText:
            //         'To ensure brand safety, undesired media placements on Pangle can be added to block list to help prevent your ads from appearing. However, this will decrease the reach of your ads. ',
            //     useValueObject: false
            // }
        ]
    },
    {
        title: 'Targeting',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'subSection',
                opened: true,
                title: 'Demographics',
                items: [
                    {
                        type: 'metadataInput',
                        model: 'location_ids',
                        identifier: 'tiktok_adgroup-location_ids',
                        label: 'Locations',
                        metadataType: 'tiktokLocations',
                        tooltip: 'Your ad review results are returned separately for each location if you select more than 1 targeting country/region.',
                        config: {
                            advertiserId: '7199594366875205634',
                            placements: '["PLACEMENT_TIKTOK"]',
                            objective: 'REACH'
                        }
                    },
                    {
                        type: 'buttonGroup',
                        model: 'gender',
                        identifier: 'tiktok_adgroup-gender',
                        label: 'Gender',
                        options: {
                            GENDER_UNLIMITED: 'All',
                            GENDER_MALE: 'Male',
                            GENDER_FEMALE: 'Female'
                        }
                    },
                    {
                        type: 'checkboxList',
                        model: 'age_groups',
                        label: 'Age groups',
                        identifier: 'tiktok_adgroup-age_groups',
                        selectType: 'multiple',
                        options: {
                            '': '',
                            AGE_13_17: '13-17',
                            AGE_18_24: '18:24',
                            AGE_25_34: '25-34',
                            AGE_35_44: '35-44',
                            AGE_45_54: '45-54',
                            AGE_55_100: '55+'
                        }
                    },
                    {
                        type: 'metadataInput',
                        model: 'languages',
                        identifier: 'tiktok_adgroup-languages',
                        label: 'Languages',
                        metadataType: 'tiktokLanguages',
                        tooltip: 'Select the targeting language based on the most prevalent language used in your selected location',
                        config: {
                            advertiserId: '7199594366875205634'
                        }
                    },
                    {
                        type: 'buttonGroup',
                        model: 'spending_power',
                        identifier: 'tiktok_adgroup-spending_power',
                        label: 'Spending power',
                        tooltip: 'High spending users typically spend more on purchases than the average user',
                        options: {
                            ALL: 'All',
                            HIGH: 'High'
                        }
                    },
                    {
                        type: 'switch',
                        model: 'is_hfss',
                        identifier: 'tiktok_adgroup-is_hfss',
                        label: 'Is promoted content is HFSS foods?',
                        tooltip:
                            'Whether the promoted content is HFSS foods (foods that are high in fat, salt, or sugar). Please note that the European market prohibits the promotion of HFSS foods to underage users'
                    }
                ]
            },
            {
                type: 'subSection',
                opened: true,
                title: 'Interests',
                items: [
                    {
                        type: 'metadataInput',
                        model: 'interest_keyword_ids',
                        identifier: 'tiktok_adgroup-interest_keyword_ids',
                        label: 'Interests & Behaviours',
                        metadataType: 'tiktokInterestsAndBehaviors',
                        tooltip: 'Define your users by selecting creator, video, and hashtag interactions and interests.',
                        config: {
                            advertiserId: '7199594366875205634'
                            // placements: '["PLACEMENT_TIKTOK"]',
                            // objective: 'REACH'
                        }
                    }
                ]
            },
            {
                type: 'subSection',
                opened: true,
                title: 'Device',
                items: [
                    {
                        type: 'buttonGroup',
                        model: 'operating_systems',
                        label: 'Operating systems',
                        identifier: 'tiktok_adgroup-operating_systems',
                        tooltip: 'Device operating systems that you want to target.',
                        options: {
                            ALL: 'All',
                            ANDROID: 'Android',
                            IOS: 'iOS'
                        }
                    },

                    {
                        type: 'checkboxList',
                        model: 'network_types',
                        identifier: 'tiktok_adgroup-network_types',
                        label: 'Network types',
                        tooltip: 'Device connection types that you want to target.',
                        selectType: 'multiple',
                        options: {
                            '': '',
                            unlimited: 'ALL',
                            WIFI: 'wifi',
                            '2G': '2G',
                            '3G': '3G',
                            '4G': '4G',
                            '5G': '5G'
                        }
                    },
                    {
                        type: 'select',
                        model: 'min_android_version',
                        identifier: 'tiktok_adgroup-min_android_version',
                        label: 'Minimum android version',
                        condition: "blockData.operating_systems === 'ANDROID'",
                        tooltip: 'Minimum device Android version.',
                        options: {
                            '': '',
                            ALL: 'No limitation',
                            '4.0': 'Android 4.0 and above',
                            '4.1': 'Android 4.1 and above',
                            '4.2': 'Android 4.2 and above',
                            '4.3': 'Android 4.3 and above',
                            '4.4': 'Android 4.4 and above',
                            '5.0': 'Android 5.0 and above',
                            '5.1': 'Android 5.1 and above',
                            '6.0': 'Android 6.0 and above',
                            '7.0': 'Android 7.0 and above',
                            '7.1': 'Android 7.1 and above',
                            '8.0': 'Android 8.1 and above',
                            '8.1': 'Android 8.1 and above',
                            '9.0': 'Android 9.0 and above',
                            '10.0': 'Android 10.0 and above',
                            '10.1': 'Android 10.1 and above',
                            '10.2': 'Android 10.2 and above',
                            '10.3': 'Android 10.3 and above',
                            '10.5': 'Android 10.5 and above',
                            '10.6': 'Android 10.6 and above',
                            '11.0': 'Android 11.0 and above',
                            '12.0': 'Android 12.0 and above'
                        }
                    },
                    // TODO: To be discussed wheter that field or the one below
                    {
                        type: 'buttonGroup',
                        model: 'ios14_targeting',
                        label: 'iOS devices',
                        identifier: 'tiktok_adgroup-ios14_targeting',
                        tooltip: 'The iOS devices that you want to target.',
                        condition: "blockData.operating_systems === 'IOS'",
                        options: {
                            UNSET: 'Unset',
                            IOS14_MINUS: 'iOS 14 minus',
                            IOS14_PLUS: 'iOS 14 plus'
                        }
                    },
                    {
                        type: 'select',
                        model: 'min_ios_version',
                        label: 'Minimum ios version',
                        identifier: 'tiktok_adgroup-min_ios_version',
                        tooltip: 'Minimum device ios version.',
                        condition: "blockData.operating_systems === 'IOS'",
                        options: {
                            '': '',
                            ALL: 'No limitation',
                            '12.0': 'iOS 12.0 and above',
                            '13.0': 'iOS 13.0 and above',
                            '14.0': 'iOS 14.0 and above',
                            '15.0': 'iOS 15.0 and above',
                            '16.0': 'iOS 16.0 and above',
                            '16.6': 'iOS 16.6 and above'
                        }
                    },

                    {
                        type: 'switch',
                        identifier: 'tiktok_adgroup-is_device_price_set',
                        model: 'is_device_price_set',
                        label: 'Set device price'
                    },
                    {
                        type: 'number',
                        tooltip: 'Min device price',
                        identifier: 'tiktok_adgroup-min_device_price',
                        model: 'min_device_price',
                        condition: 'blockData.is_device_price_set',
                        label: 'Min device price'
                    },
                    {
                        type: 'number',
                        tooltip: 'Max device price',
                        identifier: 'tiktok_adgroup-max_device_price',
                        model: 'max_device_price',
                        condition: 'blockData.is_device_price_set',
                        label: 'Max device price'
                    },
                    {
                        type: 'metadataInput',
                        model: 'device_model_ids',
                        identifier: 'tiktok_adgroup-device_model_ids',
                        label: 'Device models',
                        metadataType: 'tiktokDeviceModels',
                        tooltip: 'Device models',
                        config: {
                            advertiserId: '7199594366875205634',
                            osType: '{{{[[blockModel]].operating_systems}}}'
                        }
                    },
                    {
                        type: 'metadataInput',
                        model: 'carrier_ids',
                        identifier: 'tiktok_adgroup-carrier_ids',
                        condition: 'blockData.location_ids && blockData.location_ids.length',
                        label: 'Carriers',
                        metadataType: 'tiktokCarriers',
                        tooltip: 'Carriers',
                        config: {
                            advertiserId: '7199594366875205634',
                            locations: '{{{[[blockModel]].location_ids ? JSON.stringify([[blockModel]].location_ids.map(item=>item.region_code)) : undefined}}}'
                        }
                    }
                ]
            }
        ]
    },
    {
        title: 'Content exclusions',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        condition: `['REACH','VIDEO_VIEWS','ENGAGEMENT'].includes(additionalVars.tiktokCampaign.${MODEL_PUBLISH_METADATA}.objective_type)`,
        opened: true,
        items: [
            {
                type: 'select',
                model: 'brand_safety_type',
                identifier: 'tiktok_adgroup-brand_safety_type',
                label: 'Brand safety type. ',
                tooltip: 'Valid only when placements is set as PLACEMENT_TIKTOK. Default value: NO_BRAND_SAFETY',
                options: {
                    '': '',
                    NO_BRAND_SAFETY: 'FULL INTENTORY',
                    STANDARD_INVENTORY: 'STANDART INVENTORY',
                    LIMITED_INVENTORY: 'LIMITED INVENTORY',
                    THIRD_PARTY: 'THIRD PARTY'
                }
            },
            {
                type: 'select',
                model: 'brand_safety_partner',
                identifier: 'tiktok_adgroup-brand_safety_partner',
                label: 'Brand safety partner.',
                tooltip: 'Required only when brand_safety_type is THIRD_PARTY, and placements needs to be set as PLACEMENT_TIKTOK.',
                options: {
                    '': '',
                    IAS: 'IOSA',
                    OPEN_SLATE: 'OPEN SLATE'
                }
            }
        ]
    },
    {
        title: 'Budget & schedule',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'budget_mode',
                identifier: 'tiktok_adgroup-budget_mode',
                label: 'Budget type',
                tooltip: 'Type of the budget',
                options: {
                    BUDGET_MODE_DAY: 'Daily budget',
                    BUDGET_MODE_TOTAL: 'Lifetime budget'
                    // BUDGET_MODE_DYNAMIC_DAILY_BUDGET: 'Dynamic daily budget'
                }
            },
            {
                type: 'number',
                tooltip: 'Budget',
                model: 'budget',
                identifier: 'tiktok_adgroup-budget',
                label: 'Budget'
            },
            {
                type: 'buttonGroup',
                model: 'schedule_type',
                identifier: 'tiktok_adgroup-schedule_type',
                label: 'Schedule type',
                condition: "blockData.budget_mode !== 'BUDGET_MODE_TOTAL'",
                tooltip: 'Schedule type',
                options: {
                    SCHEDULE_START_END: 'Schedule start end',
                    SCHEDULE_FROM_NOW: 'Schedule from now'
                }
            },

            {
                type: 'buttonGroup',
                model: 'schedule_type',
                identifier: 'tiktok_adgroup-schedule_type',
                label: 'Schedule type',
                condition: "blockData.budget_mode === 'BUDGET_MODE_TOTAL'",
                tooltip: 'Schedule type',
                options: {
                    SCHEDULE_START_END: 'Schedule start end'
                }
            },
            {
                type: 'date',
                model: 'schedule_start_time',
                identifier: 'tiktok_adgroup-schedule_start_time',
                label: 'Start date'
            },
            {
                type: 'date',
                model: 'schedule_end_time',
                identifier: 'tiktok_adgroup-schedule_end_time',
                condition: "blockData.schedule_type ==='SCHEDULE_START_END'",
                label: 'End date'
            },
            {
                type: 'text',
                model: 'dayparting',
                identifier: 'tiktok_adgroup-dayparting',
                label: 'Dayparting',
                tooltip:
                    'Ad delivery arrangement, in the format of a string that consists of 48 x 7 characters. Each character is mapped to a 30-minute timeframe from Monday to Sunday. Each character can be set to either 0 or 1.',
                placeholder: 'Eg. all-0 or 1-3',
                useValueObject: false
            }
        ]
    },
    {
        title: 'Bidding & Optimization',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA,
        opened: true,
        items: [
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'tiktok_adgroup-optimization_goal',
                condition: 'false',
                label: 'Optimization goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    '': '',
                    CLICK: 'Click',
                    CONVERT: 'Conversion',
                    INSTALL: 'Installation',
                    IN_APP_EVENT: 'In-app event',
                    SHOW: 'Impression',
                    VIDEO_VIEW: 'Play video',
                    REACH: 'Reach',
                    LEAD_GENERATION: 'Leads',
                    FOLLOWERS: 'Follows',
                    PROFILE_VIEWS: 'Profile Visits',
                    VALUE: 'Maximize ROAS (Return on Ad Spend)',
                    MT_LIVE_ROOM: 'Live shopping room viewer retention',
                    PRODUCT_CLICK_IN_LIVE: 'Product link clicks duritng a live session',
                    ENGAGED_VIEW: '6-second views',
                    ENGAGED_VIEW_FIFTEEN: '15-second views',
                    TRAFFIC_LANDING_PAGE_VIEW: 'Landing Page view'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'tiktok_adgroup-optimization_goal',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'REACH'`,
                label: 'Optimization goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    '': '',
                    '': '',
                    REACH: 'Reach'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'tiktok_adgroup-optimization_goal',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'TRAFFIC'`,
                label: 'Optimization goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    '': '',
                    '': '',
                    CLICK: 'Click',
                    TRAFFIC_LANDING_PAGE_VIEW: 'Landing Page view'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'tiktok_adgroup-optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'VIDEO_VIEWS'`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    '': '',
                    '': '',
                    PRODUCT_CLICK_IN_LIVE: 'Product link clicks duritng a live session',
                    ENGAGED_VIEW: '6-second views'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'tiktok_adgroup-optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'ENGAGEMENT'`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    '': '',
                    '': '',
                    FOLLOWERS: 'Follows',
                    PROFILE_VIEWS: 'Profile Visits'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'tiktok_adgroup-optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'ENGAGEMENT'`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    '': '',
                    '': '',
                    FOLLOWERS: 'Follows',
                    PROFILE_VIEWS: 'Profile Visits'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'tiktok_adgroup-optimization_goal',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'APP_PROMOTION'`,
                label: 'Optimization goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    '': '',
                    '': '',
                    CLICK: 'Click'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'tiktok_adgroup-optimization_goal',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'LEAD_GENERATION'`,
                label: 'Optimization goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    '': '',
                    '': '',
                    LEAD_GENERATION: 'Leads'
                }
            },
            {
                type: 'select',
                model: 'optimization_goal',
                identifier: 'tiktok_adgroup-optimization_goal',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'WEB_CONVERSIONS'`,
                label: 'Optimization goal',
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example with App installs, social platform delivers your ad to people who are more likely to install your app.",
                options: {
                    '': '',
                    '': '',
                    CLICK: 'Click',
                    CONVERT: 'Conversion'
                }
            },
            {
                type: 'number',
                identifier: 'tiktok_adgroup-frequency',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'REACH'`,
                tooltip: 'Frequency, together with frequency_schedule, controls how often people see your ad (only available for REACH ads).',
                model: 'frequency',
                label: 'Frequency'
            },
            {
                type: 'number',
                tooltip:
                    'Frequency_schedule, together with frequency, controls how often people see your ad (only available for REACH ads). See frequency fields for more.',
                model: 'frequency_schedule',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'REACH'`,
                identifier: 'tiktok_adgroup-frequency_schedule',
                label: 'Frequency schedule'
            },
            {
                type: 'select',
                model: 'bid_type',
                identifier: 'tiktok_adgroup-bid_type',
                label: 'Bid strategy',
                tooltip:
                    'Choose bid strategy for this campaign to suit your specific business goals. Each strategy has tradeoffs and may be available for certain optimization_goals',
                options: {
                    '': '',
                    BID_TYPE_CUSTOM: 'Custom bid type(cost cap)',
                    BID_TYPE_NO_BID: 'No bid type(lowest cost)'
                }
            },

            {
                type: 'number',
                tooltip: 'Bid price',
                condition: "blockData.bid_type === 'BID_TYPE_CUSTOM'",
                identifier: 'tiktok_adgroup-bid_price',
                model: 'bid_price',
                label: 'Bid price'
            },
            {
                type: 'select',
                model: 'billing_event',
                identifier: 'tiktok_adgroup-billing_event',
                label: 'Billing event',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'REACH'`,
                tooltip: 'The billing event for this ad set',
                options: {
                    '': '',
                    OCPM: 'Impression (oCPM)'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                identifier: 'tiktok_adgroup-billing_event',
                label: 'Billing event',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'TRAFFIC'`,
                tooltip: 'The billing event for this ad set',
                options: {
                    '': '',
                    CPC: 'Click (CPC)'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                identifier: 'tiktok_adgroup-billing_event',
                label: 'Billing event',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'VIDEO_VIEWS'`,
                tooltip: 'The billing event for this ad set',
                options: {
                    '': '',
                    CPV: '6-second views (Focused view) (CPV)'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                identifier: 'tiktok_adgroup-billing_event',
                label: 'Billing event',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'ENGAGEMENT'`,
                tooltip: 'The billing event for this ad set',
                options: {
                    '': '',
                    OCPM: 'Impression (oCPM)'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                identifier: 'tiktok_adgroup-billing_event',
                label: 'Billing event',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'APP_PROMOTION'`,
                tooltip: 'The billing event for this ad set',
                options: {
                    '': '',
                    CPC: 'Click (CPC)'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                identifier: 'tiktok_adgroup-billing_event',
                label: 'Billing event',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'LEAD_GENERATION'`,
                tooltip: 'The billing event for this ad set',
                options: {
                    '': '',
                    OCPM: 'Impression (oCPM)'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                identifier: 'tiktok_adgroup-billing_event',
                label: 'Billing event',
                condition: `additionalVars.tiktok_campaign.${MODEL_PUBLISH_METADATA}.objective_type === 'WEB_CONVERSIONS'`,
                tooltip: 'The billing event for this ad set',
                options: {
                    '': '',
                    OCPM: 'Impression (oCPM)'
                }
            },
            {
                type: 'select',
                model: 'deep_bid_type',
                identifier: 'tiktok_adgroup-deep_bid_type',
                label: 'Deep bid price',
                tooltip: 'Bidding strategy for in-app events',
                options: {
                    '': '',
                    DEFAULT: 'Default',
                    MIN: 'Double bid',
                    PACING: 'Automatic optimization',
                    VO_MIN: 'Bid with a cost cap',
                    VO_MIN_ROAS: 'Bid with a ROAS goal',
                    VO_HIGHEST_VALUE: 'Bid without cost cap or ROAS goal',
                    AEO: 'Bid for App Event Optimization.'
                }
            },
            {
                type: 'buttonGroup',
                model: 'pacing',
                identifier: 'tiktok_adgroup-pacing',
                condition: "blockData.bid_type === 'BID_TYPE_CUSTOM'",
                label: 'Pacing',
                tooltip: 'Pacing',
                options: {
                    PACING_MODE_SMOOTH: 'Smooth',
                    PACING_MODE_FAST: 'Fast'
                }
            },
            {
                type: 'buttonGroup',
                model: 'pacing',
                identifier: 'tiktok_adgroup-pacing',
                condition: "blockData.bid_type !== 'BID_TYPE_CUSTOM'",
                label: 'Pacing',
                tooltip: 'Pacing',
                options: {
                    PACING_MODE_SMOOTH: 'Smooth'
                }
            }
        ]
    }
    // {
    //     title: 'Identity',
    //     itemType: 'block',
    //     type: 'multiInput',
    //     blockModel: MODEL_PUBLISH_METADATA,
    //     opened: true,
    //     items: [
    //         {
    //             type: 'select',
    //             model: 'identity_type',
    //             identifier: 'tiktok_adgroup-identity_type',
    //             label: 'Identity type',
    //             tooltip: 'Required and only valid when objective_type is SHOP_PURCHASES or PRODUCT_SALES. See Identities for details.',
    //             options: {
    //                 '': '',
    //                 AUTH_CODE: 'Authorized Post User',
    //                 TT_USER: 'TikTok Business Account User',
    //                 BC_AUTH_TT: 'The TikTok account that a Business Center is authorized to access'
    //             }
    //         },
    //         {
    //             type: 'text',
    //             model: 'identity_authorized_bc_id',
    //             identifier: 'tiktok_adgroup-identity_authorized_bc_id',
    //             label: 'ID of the Business Center',
    //             tooltip:
    //                 'ID of the Business Center that a TikTok Account User in Business Center identity is associated with. Required when identity_type is BC_AUTH_TT.',
    //             useValueObject: false
    //         },
    //         {
    //             type: 'text',
    //             model: 'store_authorized_bc_id',
    //             identifier: 'tiktok_adgroup-store_authorized_bc_id',
    //             label: 'Store authorized Business center id',
    //             tooltip:
    //                 'ID of the Business Center that is authorized to access the store (store_id). Required when objective_type is SHOP_PURCHASES or PRODUCT_SALES.',
    //             useValueObject: false
    //         },
    //         {
    //             type: 'text',
    //             model: 'catalog_authorized_bc_id',
    //             identifier: 'tiktok_adgroup-catalog_authorized_bc_id',
    //             tooltip: 'For catalogs in Business Center, you must specify the ID of the Business Center that a catalog belongs to',
    //             label: 'Catalog Business Center autorized id',
    //             useValueObject: false
    //         }
    //     ]
    // }
    // {
    //     title: 'General',
    //     itemType: 'block',
    //     type: 'multiInput',
    //     blockModel: MODEL_PUBLISH_METADATA,
    //     opened: true,
    //     items: [
    //         {
    //             type: 'buttonGroup',
    //             model: 'operation_status',
    //             identifier: 'tiktok_adgroup-operation_status',
    //             label: 'Operation status',
    //             tooltip: 'The status of the ad group when created.',
    //             options: {
    //                 ENABLE: 'Enable',
    //                 DISABLE: 'Disable'
    //             }
    //         },

    //         {
    //             type: 'buttonGroup',
    //             model: 'shipping_ads_type',
    //             identifier: 'tiktok_adgroup-shipping_ads_type',
    //             label: 'Shipping ads type',
    //             options: {
    //                 UNSET: 'Unset',
    //                 VIDEO: 'Video',
    //                 LIVE: 'Live',
    //                 CATALOG_LISTING_ADS: 'Catalog listing ads'
    //             }
    //         },
    //         {
    //             type: 'buttonGroup',
    //             model: 'product_source',
    //             identifier: 'tiktok_adgroup-product_source',
    //             label: 'Product source',
    //             options: {
    //                 UNSET: 'Unset',
    //                 CATALOG: 'Catalog',
    //                 STORE: 'Store',
    //                 SHOWCASE: 'Showcase'
    //             }
    //         },
    //         {
    //             type: 'buttonGroup',
    //             model: 'shopping_ads_retargeting_type',
    //             identifier: 'tiktok_adgroup-shopping_ads_retargeting_type',
    //             label: 'Shopping ads retargeting type',
    //             options: {
    //                 OFF: 'Off',
    //                 LAB1: 'LAB1',
    //                 LAB2: 'LAB2',
    //                 LAB3: 'LAB3'
    //             }
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'Shopping ads retargeting actions days',
    //             identifier: 'tiktok_adgroup-shopping_ads_retargeting_action_days',
    //             model: 'shopping_ads_retargeting_actions_days',
    //             label: 'Shopping ads retargeting actions days'
    //         },
    //         {
    //             type: 'buttonGroup',
    //             identifier: 'tiktok_adgroup-included_custom_actions-code',
    //             model: 'included_custom_actions.code',
    //             label: 'Included custom actions code',

    //             options: {
    //                 VIEW_PRODUCT: 'View product',
    //                 ADD_TO_CART: 'Add to cart',
    //                 PURCHASE: 'Purchase'
    //             }
    //         },
    //         {
    //             type: 'number',
    //             identifier: 'tiktok_adgroup-included_custom_actions-days',
    //             tooltip: 'Included custom actions days',
    //             model: 'included_custom_actions.days',
    //             label: 'Included custom actions days'
    //         },
    //         {
    //             type: 'buttonGroup',
    //             model: 'excluded_custom_actions.code',
    //             identifier: 'tiktok_adgroup-excluded_custom_actions-code',
    //             label: 'Excluded custom actions code',
    //             options: {
    //                 VIEW_PRODUCT: 'View product',
    //                 ADD_TO_CART: 'Add to cart',
    //                 PURCHASE: 'Purchase'
    //             }
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'Excluded custom actions days',
    //             identifier: 'tiktok_adgroup-excluded_custom_actions-days',
    //             model: 'excluded_custom_actions.days',
    //             label: 'Excluded custom actions days'
    //         },
    //         {
    //             type: 'text',
    //             model: 'store_id',
    //             identifier: 'tiktok_adgroup-store_id',
    //             label: 'Store id',
    //             tooltip: 'ID of the TikTok Storefront or TikTok Shop. Valid and required only when objective_type is SHOP_PURCHASES or PRODUCT_SALES.',
    //             useValueObject: false
    //         },

    //         {
    //             type: 'text',
    //             model: 'catalog_id',
    //             identifier: 'tiktok_adgroup-catalog_id',
    //             tooltip: 'Catalog ID. Required when the corresponding campaign objective_type is PRODUCT_SALES',
    //             label: 'Catalog ID',
    //             useValueObject: false
    //         },
    //         {
    //             type: 'text',
    //             model: 'product_set_id',
    //             identifier: 'tiktok_adgroup-product_set_id',
    //             tooltip: 'ProductSet ID of the catalog. 0 means selecting all product sets. The default value is 0.',
    //             label: 'Product set id',
    //             useValueObject: false
    //         },

    //         {
    //             type: 'number',
    //             identifier: 'tiktok_adgroup-roas_bid',
    //             tooltip: 'ROAS goal for Value Optimization. Required when deep_bid_type is VO_MIN_ROAS.Value range: 0.01-1000.',
    //             model: 'roas_bid',
    //             label: 'ROAS bid'
    //         },

    //         {
    //             type: 'text',
    //             model: 'app_id',
    //             identifier: 'tiktok_adgroup-app_id',
    //             label: 'App id',
    //             placeholder: 'App id',
    //             useValueObject: false
    //         },

    //         {
    //             type: 'number',
    //             tooltip: '',
    //             identifier: 'tiktok_adgroup-next_day_retention',
    //             model: 'next_day_retention',
    //             label: 'Bid price'
    //         },

    //         {
    //             type: 'number',
    //             tooltip:
    //                 'Day 2 retention ratio. Formula: next_day_retention = conversion_bid_price/deep_cpa_bid. Value range is (0,1]. Only valid when placements is PLACEMENT_PANGLE and secondary_optimization_event is NEXT_DAY_OPEN.',
    //             model: 'conversion_price',
    //             identifier: 'tiktok_adgroup-conversion_price',
    //             label: 'Conversion price'
    //         }
    //     ]
    // }
];

export default settings;
