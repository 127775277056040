import React, { useMemo } from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Translation from 'components/data/Translation';
import { FieldSetCopy } from 'components/input/Copy';
import CreativeSelector from 'components/input/CreativeSelector';
import Divider from 'components/ui-components-v2/Divider';
import { withResources } from 'components/data/Resources';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import brickSetup from './setup';

import './styles/ad-setup.scss';

interface Props {
    handleSelectCreatives: (creatives: CreativeV2[]) => void;
    tiktok_cta: {
        [key: string]: string;
    };
}

const SingleFrame: React.FC<Props> = ({ handleSelectCreatives, tiktok_cta }) => {
    const { adSetup, creatives, onChange } = useAdSetupContext();
    const tikTokAdSetup: any = adSetup; // TODO Temporary because the adsetup is typed for meta and not in general

    const inputValidators = useMemo(() => {
        return {
            displayName: ValidateHelpers.getInputValidators('tiktok_ad', `tiktok_ad-ad_setup-displayName`),
            adText: ValidateHelpers.getInputValidators('tiktok_ad', `tiktok_ad-ad_setup-adText`),
            landingPageUrl: ValidateHelpers.getInputValidators('tiktok_ad', `tiktok_ad-ad_setup-landingPageUrl`)
        };
    }, []);

    const { multiple, canEditCreative, selectors, templateTypes = [] } = brickSetup?.config?.general?.creativeSelector || {};

    return (
        <Accordion
            square
            title={Translation.get('adSetup.main', 'bricks')}
            classes={{ root: 'ad-setup__accordion__root', details: 'ad-setup__accordion__details', summaryRoot: 'ad-setup__accordion__summary__root' }}>
            <div className="ad-setup__accordion__inputs">
                <CreativeSelector
                    onMutation={(creatives) => handleSelectCreatives(creatives)}
                    multiple={multiple}
                    canEditCreative={canEditCreative}
                    value={creatives}
                    selectors={selectors}
                    templateTypes={templateTypes}
                />
                <Divider />
                <FieldSetCopy
                    label={Translation.get('adSetup.tiktokAd.displayName', 'bricks')}
                    useValueObject={false}
                    onMutation={(newValue) => onChange('displayName', newValue, true)}
                    value={tikTokAdSetup?.items?.[0].displayName}
                    validators={inputValidators.displayName}
                />
                <FieldSetCopy
                    label={Translation.get('adSetup.tiktokAd.adText', 'bricks')}
                    useValueObject={false}
                    onMutation={(newValue) => onChange('adText', newValue, true)}
                    value={tikTokAdSetup?.items?.[0].adText}
                    validators={inputValidators.adText}
                />
                <FieldSetSelectInput
                    label={Translation.get('adSetup.tiktokAd.callToAction', 'bricks')}
                    useValueObject={false}
                    value={tikTokAdSetup.cta}
                    onMutation={(newValue) => onChange('cta', newValue)}
                    options={tiktok_cta}
                />
                <FieldSetCopy
                    label={Translation.get('adSetup.tiktokAd.landingPageUrl', 'bricks')}
                    onMutation={(newValue) => onChange('landingPageUrl', newValue, true)}
                    value={tikTokAdSetup?.items?.[0].landingPageUrl}
                    useValueObject={false}
                    validators={inputValidators.landingPageUrl}
                />
            </div>
        </Accordion>
    );
};

export default withResources(SingleFrame, ['tiktok_cta']);
