import { PlacementsSection } from 'components/bricks/types/placement.type';
import Translation from 'components/data/Translation';

const placements: PlacementsSection[] = [
    {
        key: 'feed',
        label: Translation.get('adSetup.placements.feed', 'bricks'),
        children: [
            {
                recommendedHeight: 16,
                recommendedWidth: 9,
                minWidth: 500,
                type: 'feed',
                key: 'tiktok-feed',
                label: 'Tiktok Video Feed',
                platform: 'tiktok',
                channel: 'tiktok',
                frameWidth: 340,
                multiFrame: false
            }
        ]
    }
];

export default placements;
