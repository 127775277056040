import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const metadata: IEditorBlock[] = [
    {
        type: 'subSection',
        opened: true,
        title: 'Copy',
        items: [
            {
                type: 'textMultiLanguage',
                model: 'text',
                label: 'Text',
                multiline: true,
                tooltip: 'Text over 125 characters is allowed, but may be truncated',
                maxLength: 2200
            },
            {
                type: 'textMultiLanguage',
                model: 'headline',
                label: 'Headline',
                tooltip: 'Text over 25 characters is allowed, but may be truncated'
            },
            {
                type: 'textMultiLanguage',
                model: 'linkDescription',
                label: 'Link description',
                tooltip: 'Text over 20 characters is allowed, but may be truncated'
            },
            {
                type: 'textMultiLanguage',
                model: 'displayUrl',
                label: 'Display URL'
            }
        ]
    }
];

export default metadata;
